<template>
	<div class="center">
		<search-bar />
		<div class="title">个人中心</div>

		<el-tabs v-model="activeName" @tab-click="handleClick">
			<el-tab-pane label="观看记录" name="1">
				<!-- <my-history /> -->
			</el-tab-pane>
			<el-tab-pane label="我的看单" name="2">
				<!-- <my-watch /> -->
			</el-tab-pane>
			<el-tab-pane label="我的探索" name="3">
				<!-- <my-explore /> -->
			</el-tab-pane>
			<el-tab-pane label="个人信息" name="4">
				<!-- <center-main /> -->
			</el-tab-pane>
		</el-tabs>
		<router-view></router-view>
	</div>
</template>

<script>
import centerMain from "./center-main"
import searchBar from "./search-bar"
import myExplore from "./my-explore"
import myWatch from "./my-watch"
import myHistory from "./my-history"
export default {
	name: "center",
	data() {
		return {
			// type: "resultEmpty",
			// type: "result",
			type: "normal",

			backgroundImage: require("@assets/index.png"),
			avatarImage: require("@assets/avatar.png"),
			wantIcon: require("@assets/explore/want.png"),
			timeIcon: require("@assets/aside/aside7.png"),
			emptyIcon: require("@assets/explore/empty.png"),
			searchWidth: 0,
			searchPlaceHolder: "复仇者联盟",
			searchVal: "",
			user: {
				name: "胖虎不是铁憨憨",
			},

			moiveList: [],
			activeName: "4",
		}
	},
	computed: {
		isLogin() {
			console.log(this.$store.state.userData)
			return this.$store.state.userData && this.$store.state.userData.token
		},
		userData() {
			return this.$store.state.userData
		},
	},
	watch: {
		"$store.state.watchJump": function(newVal, oldVal) {
			console.log("我是watch来的数据", newVal)
			this.activeName = String(newVal)
			if (this.activeName === "1") this.$router.push("/center/history")
			if (this.activeName === "2") this.$router.push("/center/watch")
			if (this.activeName === "3") this.$router.push("/center/explore")
			if (this.activeName === "4") this.$router.push("/center/main")
		},
	},
	methods: {
		async init() {
			// const result = await this.$get("/index");
			// console.log(result);
		},
		handleClick(tab, event) {
			console.log(tab, event, this.activeName)
			if (this.activeName === "1") this.$router.push("/center/history")
			if (this.activeName === "2") this.$router.push("/center/watch")
			if (this.activeName === "3") this.$router.push("/center/explore")
			if (this.activeName === "4") this.$router.push("/center/main")
		},
		handleDefaultTag() {
			switch(this.$route.path) {
				case '/center/history':
					this.activeName = "1"
					break
				case '/center/watch':
					this.activeName = "2"
					break
				case '/center/explore':
					this.activeName = "3"
					break
				case '/center/main':
					this.activeName = "4"
					break
			}
		}
	},
	mounted() {
		if (!this.isLogin) {
			this.$message.warning("当前用户未登录")
			setTimeout(() => {
				this.$router.push("/")
			}, 500)
		} else {
			this.init()
			this.handleDefaultTag()
			// let activeName = this.$route.params.activeName
			// console.log(this.$route)
			// if (activeName) {
			// 	this.activeName = String(activeName)
			// }
		}
	},
	updated() {
		this.handleDefaultTag()
	},
	components: {
		searchBar,
		centerMain,
		myExplore,
		myWatch,
		myHistory,
	},
}
</script>

<style lang="scss">
.center {
	background: rgb(19, 21, 48);
	width: calc(100% - 100px);
	height: 100%;
	overflow-y: auto;
	overflow-x: hidden;
	position: relative;
	padding: 0 50px;
	.el-tabs__item {
		width: auto !important;
	}
	.title {
		font-size: 30px;
		font-family: PingFang SC;
		font-weight: 600;
		line-height: 42px;
		color: #ffffff;
		margin-top: 80px;
		user-select: none;
		margin-bottom: 80px;
	}
}
@media screen and (max-width: 1680px) {

	.center {
		.title {
			font-size: 24px;
			margin-bottom: 40px;
		}
	}
}
</style>
